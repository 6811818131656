import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'addEmailSetup',
  data() {
    return {
      emailSetupType: {
        value: 'PAN_NUMBER',
        text: 'Pan Number'
      },
      defaultValue: {
        value: null,
        text: null
      },
      legalEntity: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      addVendorEmailData: [
        {
          vendor_name: '',
          vendor_pan_gst: '',
          to_email: [],
          cc_email: [],
          bcc_email: [],
          is_active: true
        }
      ],
      addVendorEmailFields: [
        { key: 'add', stickyColumn: true, class: 'text-center' },
        { key: 'remove', stickyColumn: true, class: 'text-center' },
        {
          key: 'vendor_name',
          class: 'col-fix'
        },
        {
          key: 'vendor_pan_gst',
          class: 'col-fix'
        },
        {
          key: 'to_email',
          label: 'Vendor Email',
          class: 'col-fix'
        },
        {
          key: 'cc_email',
          label: 'Internal CC',
          class: 'col-fix'
        },
        {
          key: 'bcc_email',
          label: 'External CC',
          class: 'col-fix'
        },
        {
          key: 'is_active',
          label: 'Active',
          class: 'text-center'
        }
      ],
      showValueSetModal: false,
      editMode: false,
      loader: false
    };
  },
  validations: {
    emailSetupType: {
      text: { required }
    },
    legalEntity: {
      text: { required }
    }
    // ouName: {
    //   text: { required }
    // }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = !this.editMode;
        }
        if (actionName === 'save') {
          this.editMode = false;
          this.addEmailSetupData();
        }
      }
    });
  },
  methods: {
    addEmailSetupData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const addEmailData = this.addVendorEmailData.map(key => {
          return {
            Email_Criteria: this.emailSetupType.value,
            le_id: this.legalEntity.value,
            org_id: this.ouName.value,
            Vendor_name: key.vendor_name,
            Vendor_Pan_Gst: key.vendor_pan_gst,
            to_email: key.to_email.join(','),
            cc_email: key.cc_email.join(','),
            bcc_email: key.bcc_email.join(','),
            Is_active: key.is_active ? 'Y' : 'N'
          };
        });
        const payload = {
          data: addEmailData
        };
        this.loader = true;
        this.$store
          .dispatch('gstMaster/addEmailSetupData', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 201) {
              this.editMode = false;
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
              this.addVendorEmailData = [
                {
                  vendor_name: '',
                  vendor_pan_gst: '',
                  to_email: [],
                  cc_email: [],
                  bcc_email: [],
                  is_active: true
                }
              ];
            } else {
              this.$bvToast.toast(response.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    openValueSetModal(vsetCode) {
      if (
        (vsetCode === 'EMAIL_SETUP_TYPE' && !this.legalEntity.text) ||
        (vsetCode === 'GST_OPERATING_UNIT' && !this.legalEntity.text)
      ) {
        this.$bvToast.toast('Please select Legal Entity', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        return;
      }
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.GST_OPERATING_UNIT) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GST_OPERATING_UNIT) {
        this.ouName = {
          value: item.org_id,
          text: item.erp_org_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.EMAIL_SETUP_TYPE) {
        this.emailSetupType = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === this.emailSetupType.value) {
        this.emailSetupType = this.defaultValue;
      } else if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      } else if (vsetCode === this.ouName.value) {
        this.ouName = this.defaultValue;
      } else if (vsetCode === this.addEditDocSeqData[index].vendor_name) {
        this.addEditDocSeqData[index].vendor_name = '';
      } else if (vsetCode === this.addEditDocSeqData[index].vendor_pan_gst) {
        this.addEditDocSeqData[index].vendor_pan_gst = '';
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    validateEmailGrid(emailList) {
      for (let i = 0; i < emailList.length; i++) {
        if (emailList[i]) {
          if (
            !/^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,6}$/.test(
              emailList[i].toLowerCase()
            )
          ) {
            this.$bvToast.toast('Wrong email format:' + ' ' + emailList[i], {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
            emailList[i] = null;
          }
        }
      }
    },
    convertToUpperCase(item, index, type) {
      if (type === 'panGst') {
        this.addVendorEmailData[index].vendor_pan_gst = item.toUpperCase();
      } else if (type === 'vendorName') {
        this.addVendorEmailData[index].vendor_name = item.toUpperCase();
      }
    },
    addNewRow() {
      if (this.editMode) {
        this.addVendorEmailData.push({
          vendor_name: '',
          vendor_pan_gst: '',
          to_email: [],
          cc_email: [],
          bcc_email: [],
          is_active: true
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.addVendorEmailData.splice(index, 1);
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
